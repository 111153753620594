import React from 'react';
import TermsSection from '../../sections/Terms';
import {useData} from '../../data/en/terms';

export default function Terms() {
  const data = useData();

  return (
    <TermsSection data={data}/>
  );
}
