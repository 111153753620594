import React from 'react';
import Image from 'gatsby-image';
import useEnTermsData from '../../shared/Hooks/TermsData/useEnTermsData';
import {useEnBackgroundImage} from '../../shared/Hooks/Background/useEnBackgroundImage';

export const useData = () => {
  const cards = useEnTermsData();
  const backgroundImgs = useEnBackgroundImage();

  return {
    seo: {
      title: 'General Terms of Cooperation',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.terms.childImageSharp.fixed}/>
    ),
    title: 'General Terms of Cooperation',
    topLayout: {
      title: <>Principles of cooperation</>,
      text: <>
        We invite you to familiarize yourself with the terms of cooperation with ArtProgres. Below you will find all the necessary information on cooperation and personal data protection.
      </>,
    },
    cardContainer: {
      cards,
    },
  };
};
