import {useStaticQuery, graphql} from 'gatsby';

export const useEnBackgroundImage = () => {
  return useStaticQuery(graphql`
      query {
          aboutFirm: file(relativePath: { eq: "img/BrushedText/en/about-company.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          aboutUs: file(relativePath: { eq: "img/BrushedText/en/about-us.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          advantages: file(relativePath: { eq: "img/BrushedText/en/advantages.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          case: file(relativePath: { eq: "img/BrushedText/en/case.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          caseStudy: file(relativePath: { eq: "img/BrushedText/en/case-study.png" }) {
              childImageSharp {
                  fixed(height: 250, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          clients: file(relativePath: { eq: "img/BrushedText/en/clients.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          contact: file(relativePath: { eq: "img/BrushedText/en/contact.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          process: file(relativePath: { eq: "img/BrushedText/en/process.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          projects: file(relativePath: { eq: "img/BrushedText/en/projects.png" }) {
              childImageSharp {
                  fixed(height: 200, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          terms: file(relativePath: { eq: "img/BrushedText/en/terms.png" }) {
              childImageSharp {
                  fixed(height: 200, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
      }
  `);
};
