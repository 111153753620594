import {useTermsIcons} from './useTermsIcons';

const useTermsData = () => {
  const icons = useTermsIcons();
  const data = [
    {
      title: 'General Terms of Cooperation',
      icon: icons.comment,
      href: '/pdf/OWW-2022.pdf',
      downloadText: 'Download',
    },
    {
      title: 'Complaint Form',
      icon: icons.pen,
      href: '/pdf/Formularz-reklamacyjny.pdf',
      downloadText: 'Download',
    },
    {
      title: 'GDPR information',
      icon: icons.bag,
      href: '/pdf/Informacje-RODO.pdf',
      downloadText: 'Download',
    },
  ];
  return data;
};
export default useTermsData;
